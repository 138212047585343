import React from 'react';
import { connect } from 'react-redux';

const Home = props => (
  <div>
    <h1>Welcome to Vonigo DevOps</h1>
    <ul>
        <li><a href='https://vonigo.atlassian.net/wiki/spaces/SD/overview' target='_blank' rel="noopener noreferrer">Software Development wiki</a></li>
        <li><a href='https://vonigo.atlassian.net/wiki/spaces/SD/pages/198508545/Development+and+release+process' target='_blank' rel="noopener noreferrer">Development and release process</a></li>
        <li><a href='https://jenkins.vonigo.cool/' target='_blank' rel="noopener noreferrer">Jenkins</a></li>
        <li><a href='https://octopus.vonigo.cool/' target='_blank' rel="noopener noreferrer">Octopus</a></li>
    </ul>
  </div>
);

export default connect()(Home);
