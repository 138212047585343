import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

const styles = { 
};

class NavMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: props.open
        }
    }

    openMenu = () => {
        this.setState({
          open: true
        });
    }
    
    closeMenu = () => {
        this.setState({
          open: false
        });
    }

    componentWillReceiveProps(nextProps, nextState) {
        this.setState({
            open: nextProps.open
        });
    }

    render() {
        return (
            <SwipeableDrawer
                open={this.state.open}
                onClose={this.closeMenu}
                onOpen={this.openMenu}
            >
            <div
                tabIndex={0}
                role="button"
                onClick={this.closeMenu}
                onKeyDown={this.closeMenu}
            >
                {this.props.sideList}
            </div>
        </SwipeableDrawer>
        )
    }
}

export default withStyles(styles)(NavMenu);