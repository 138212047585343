import React from 'react';
import { Route } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Layout from './components/Layout';
import Home from './components/Home';
import Bundles from './components/bundle/Bundles';

const styles = theme => ({
    root: {
      ...theme.typography,
    },
  });

class App extends React.Component {
  render() {
    return (
        <div className={this.props.classes.root}>
          <Layout>
            <Route exact path='/' component={Home} />
            <Route exact path='/bundles' component={Bundles} />
          </Layout>
        </div>
    );
}
};

export default withStyles(styles)(App);
// export default App;