import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard'

class CopyContentAction extends React.Component {

    state = { copied: false };

    handleCopyClick = () => {
        this.setState({
            copied: true
        });
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ copied: false });
    };

    render() {
        const { content, name, messageKey, classes } = this.props;
        const messageId = `${messageKey}-id`;
        return (
            <div>
                <Tooltip
                    title={`Copy ${name} to be pasted on Octopus tenant configuration.`}
                >
                    <CopyToClipboard
                        text={content}
                        onCopy={this.handleCopyClick}
                    >
                        <IconButton
                            aria-label={content}
                            color={this.state.copied ? 'primary' : 'default'}
                        >
                            {this.props.children}
                        </IconButton>
                    </CopyToClipboard>
                </Tooltip>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.copied}
                    autoHideDuration={3000}
                    onClose={this.handleClose}
                    ContentProps={{
                        'aria-describedby': { messageId },
                    }}
                    message={<span id={messageId}>Content of {name} copied to clipboard</span>}
                    action={[
                        <Button
                            color='secondary'
                            onClick={this.props.handleOctopusClick}
                        >
                            Octopus
                        </Button>,
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleClose}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </div>
        );
    }
}

export default CopyContentAction;