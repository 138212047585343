const requestBundlesType = 'REQUEST_BUNDLES';
const receiveBundlesType = 'RECEIVE_BUNDLES';
const initialState = { entries: [], isLoading: false };

export const actionCreators = {
  requestBundles: () => async (dispatch, getState) => {    
    if (getState().bundles.entries.length > 0) {
      // Don't issue a duplicate request (we already have or are loading the requested data)
      return;
    }

    dispatch({ type: requestBundlesType });

    const url = 'api/bundles/all';
    const response = await fetch(url);
    const entries = await response.json();

    dispatch({ type: receiveBundlesType, entries });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestBundlesType) {
    return {
      ...state,
      isLoading: true
    };
  }

  if (action.type === receiveBundlesType) {
    return {
      ...state,
      entries: action.entries,
      isLoading: false
    };
  }

  return state;
};
