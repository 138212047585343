import React from 'react';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Bundles';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import Bundle from './Bundle';

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 200,
    },
    menu: {
      width: 200,
    },
  });
  
class Bundles extends React.Component {

    state = { filter: '' }

    componentWillMount() {
        // This method runs when the component is first added to the page
        this.props.requestBundles();
    }

    handleChange = event => {
      this.setState({
        filter: event.target.value,
      });
    };

    filterEntries = (entries, filter) => {
        filter = filter.toLowerCase();
        return entries.filter(function (e) {
            if (filter === '') {
                e.showAll = false;
                e.filteredByBundle = false;
                return true;
            }

            const { name, clients } = e;
            if (name.toLowerCase().indexOf(filter) !== -1) {
                e.filteredByBundle = true;
                return true;
            }
            e.filteredByBundle = false;

            for (let i = 0; i < clients.length; i++) {
                const client = clients[i];

                if (client.name.toLowerCase().indexOf(filter) !== -1 || client.database.toLowerCase().indexOf(filter) !== -1) {
                    return true;
                }
                const domains = client.domains;
                for (let k = 0; k < domains.length; k++) {
                    if (domains[k].toLowerCase().indexOf(filter) !== -1) {
                        return true;
                    }
                }
            }

            return false;
        });
    }

    render() {
        const { classes, entries, isLoading } = this.props;
        const { filter } = this.state;
        const fadingDelay = '400ms';
        var filteredEntries = this.filterEntries(entries, filter);

        return (
            <div>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <br />
                        <br />
                        <br />
                        <TextField
                            id="filter"
                            label="Filter"
                            className={classes.textField}
                            value={this.state.filter}
                            onChange={this.handleChange}
                            margin="normal"
                        />
                    </Grid>
                    {!isLoading && 
                        filteredEntries.map(entry => (
                            <Grid key={entry.id} item>
                                <Bundle bundle={entry} filter={filter} />
                            </Grid>
                        )
                    )}
                </Grid>

                {isLoading && (
                    <div>
                        <Fade
                            in={isLoading}
                            style={{
                                transitionDelay: isLoading ? fadingDelay : '0ms',
                                height: isLoading ? 40 : 0,
                            }}
                            unmountOnExit
                        >
                            <CircularProgress />
                        </Fade>
                    </div>
                )}
            </div>
        )
    }
}

export default compose(
    withStyles(styles),
    connect(
        state => state.bundles,
        dispatch => bindActionCreators(actionCreators, dispatch)
    )
)(Bundles);
