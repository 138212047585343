import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import MUIDataTable from 'mui-datatables';
import DatabaseExportIcon from 'mdi-react/DatabaseExportIcon';
import WebIcon from 'mdi-react/WebIcon';
import CopyContentAction from './CopyContentAction';

const styles = theme => ({
    card: {
        minWidth: 250
    },
    cardContracted: {
        maxWidth: 250,
        transition: theme.transitions.create('max-width', {
            duration: theme.transitions.duration.shortest,
            easing: 'ease-in'
        }),
    },
    cardExpanded: {
        maxWidth: 'none',
        transition: theme.transitions.create('max-width', {
            duration: theme.transitions.duration.shortest,
            easing: 'ease-in'
        }),
    },
    cardBadge: {
        whiteSpace: 'nowrap',
    },
    cardDetails: {
    },
    actions: {
        display: 'flex',
    },
    expand: {
        marginLeft: 'auto',
    },
    expandCounter: {
        fontSize: 'small'
    },
    expandIcon: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandIconOpen: {
        transform: 'rotate(180deg)',
    },
    octopus: {
        backgroundColor: '#2f93e0',
        padding: 3,
        paddingRight: 4,
        borderRadius: 5,
        width: 20,
        height: 20
    },
    avatar: {
        margin: 10,
    },
    chip: {
        margin: theme.spacing.unit,
    },
    paper: {
        margin: theme.spacing.unit,
        padding: theme.spacing.unit
    },
    rootContent: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        width: 310,
        maxWidth: 310,
        minWidth: 310,
        maxHeight: 200

    },
    close: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4,
    },
});

const ClientCountBadge = props => {
    const { bundle, filteredClients, filter, className } = props;

    const hasFilter = filter !== null && filter !== undefined && filter.trim().length > 0;
    const hasFilteredClients = filteredClients.length !== bundle.clientsCount;

    const clientsCountBadge = !hasFilteredClients
        ? bundle.clientsCount.toString()
        : (
            <div className={className}>
                {filteredClients.length}
            </div>
        );
    if (!hasFilter) {
        return (
            <React.Fragment>
                {props.children}
            </React.Fragment>
        )
    } else {
        return (
            <Badge badgeContent={clientsCountBadge} color={hasFilter ? 'secondary' : 'primary'} className={className}>
                {props.children}
            </Badge>
        );
    }
}

class Bundle extends React.Component {
    state = {
        expanded: false,
        openMenuAnchorEl: null
    };

    handleOctopusClick = () => {
        var win = window.open(`https://octopus.vonigo.cool/app#/tenants/${this.props.bundle.id}/variables?activeTab=commonVariables`, '_blank');
        win.focus();
        this.handleMenuClose();
    };

    handleExpandClick = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    handleOpenMenuClick = event => {
        this.setState({ openMenuAnchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ openMenuAnchorEl: null });
    };

    filterEntries = (bundle, filter) => {
        filter = filter.toLowerCase();
        return bundle.filteredByBundle
            ? bundle.clients
            : bundle.clients.filter(client => {
                if (client.name.toLowerCase().indexOf(filter) !== -1 || client.database.toLowerCase().indexOf(filter) !== -1) {
                    return true;
                }
                const domains = client.domains;
                for (let k = 0; k < domains.length; k++) {
                    if (domains[k].toLowerCase().indexOf(filter) !== -1) {
                        return true;
                    }
                }
                return false;
            });
    }

    render() {
        const { classes, bundle, filter } = this.props;
        const { octopus, commaSeparatedDatabases, commaSeparatedDomains } = bundle;
        const { version, updatedAt } = octopus;
        const { openMenuAnchorEl } = this.state;
        const filteredClients = this.filterEntries(bundle, filter);

        return (
            <div>
                <Card className={classnames(classes.card, {
                        [classes.cardContracted]: !this.state.expanded,
                        [classes.cardExpanded]: this.state.expanded,
                    })}>
                    <CardHeader
                        avatar={
                            <Avatar alt={bundle.name} src={`https://octopus.vonigo.cool/api/tenants/${bundle.id}/logo`} className={classes.avatar} />
                        }
                        action={
                            <div>
                                <IconButton
                                    aria-owns={openMenuAnchorEl ? 'simple-menu' : null}
                                    aria-haspopup="true"
                                    onClick={this.handleOpenMenuClick}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={openMenuAnchorEl}
                                    open={Boolean(openMenuAnchorEl)}
                                    onClose={this.handleMenuClose}
                                >
                                    <MenuItem onClick={this.handleOctopusClick}>Octopus</MenuItem>
                                </Menu>

                            </div>
                        }
                        title={bundle.name}
                        subheader={(version && (
                            <div>
                                <span>{`${version} at `}</span>
                                <Tooltip id="tooltip-updatedAt" title={moment(updatedAt).format('LLL')} placement="bottom-start">
                                    <span>{moment(updatedAt).format('ll')}</span>
                                </Tooltip>
                            </div>
                        )) || (
                                // <LinearProgress />
                                <div />
                            )}
                    />
                    <CardActions className={classes.actions} disableActionSpacing>
                        <CopyContentAction
                            messageKey='domains'
                            name={`${bundle.name}'s domains`}
                            content={commaSeparatedDomains}
                            handleOctopusClick={this.handleOctopusClick}
                            classes={classes}
                        >
                            <WebIcon />
                        </CopyContentAction>
                        <CopyContentAction
                            messageKey='databases'
                            name={`${bundle.name}'s databases`}
                            content={commaSeparatedDatabases}
                            handleOctopusClick={this.handleOctopusClick}
                            classes={classes}
                        >
                            <DatabaseExportIcon />
                        </CopyContentAction>
                        <div className={classes.expand}>
                            <ClientCountBadge bundle={bundle} filteredClients={filteredClients} filter={filter} className={classes.cardBadge}>
                                <Chip label={bundle.clientsCount} className={classes.expandCounter} />
                            </ClientCountBadge>
                            <IconButton
                                onClick={this.handleExpandClick}
                                aria-expanded={this.state.expanded}
                                aria-label="Show more"
                            >
                                <ExpandMoreIcon
                                    className={classnames(classes.expandIcon, {
                                        [classes.expandIconOpen]: this.state.expanded,
                                    })}
                                />
                            </IconButton>
                        </div>
                    </CardActions>
                    <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                        <CardContent className={classes.cardDetails}>
                            <Paper className={classes.rootContent}>
                                <MUIDataTable
                                    data={filteredClients.map(c => [c.name, c.database, c.domains])}
                                    title='Client setup'
                                    columns={[
                                        'Client',
                                        'Database',
                                        {
                                            name: 'Domains',
                                            options: {
                                                customBodyRender: (domains, tableMeta, updateValue) => {
                                                    return (
                                                        <ul>
                                                            {domains.map((d,i) => {
                                                                return (
                                                                    <li key={`${d}_${i}`}><a href={`https://${d}`} target="_blank" rel="noopener noreferrer">{d}</a></li>
                                                                )
                                                            })}
                                                        </ul>
                                                    )
                                                }
                                            }
                                        }
                                    ]}
                                    options={{
                                        pagination: false,
                                        rowsPerPage: 99999,
                                        selectableRows: false,
                                        responsive: 'scroll'
                                    }}
                                />
                            </Paper>
                        </CardContent>
                    </Collapse>
                </Card>
            </div>
        )
    }

}

export default withStyles(styles)(Bundle);