import React from 'react';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { actionCreators } from '../store/MenuEntries';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    root: {
        width: 250,
    },
    placeholder: {
      "text-align": "center",
    },
  });

const MenuEntry = function(props) {
    return (
        <ListItem onClick={props.onClick} component={Link} to={props.to}>
            <ListItemIcon><Icon>{props.icon}</Icon></ListItemIcon>
            <ListItemText primary={props.name} />
        </ListItem>
    );
}

class Sidenav extends React.Component {
    componentWillMount() {
      // This method runs when the component is first added to the page
      this.props.requestMenuEntries();
    }
    
    render() {
        const fadingDelay = '400ms';
        const { classes, isLoading } = this.props;

        return (
            <div className={classes.root}>
            <Fade
                in={!isLoading}
                style={{
                    transitionDelay: isLoading ? fadingDelay : '0ms'
                }}
            >
                <List component="nav">
                    {this.props.entries.map(entry => (
                            <MenuEntry
                                key={entry.to}
                                onClick={this.props.onMenuEntryClick}
                                to={entry.to} name={entry.name}
                                icon={entry.icon}
                            />
                        )
                    )}
                </List>
              </Fade>
              {isLoading && (
                <div className={classes.placeholder}>
                    <Fade
                         in={isLoading}
                         style={{
                             transitionDelay: isLoading ? fadingDelay : '0ms',
                             height: isLoading ? 40 : 0,
                         }}
                         unmountOnExit
                    >
                        <CircularProgress />
                    </Fade>
                </div>
              )}
            </div>
          );
    }
}

export default compose(
    withStyles(styles),
    connect(
        state => state.menuEntries,
        dispatch => bindActionCreators(actionCreators, dispatch)
    ),
  )(Sidenav);