import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NavMenu from './NavMenu';
import Sidenav from './Sidenav';

const styles = {
    root: {
      flexGrow: 1,
    },
    flex: {
      flex: 1,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
  };

  class Layout extends React.Component {

    state = {
      auth: true,
      anchorEl: null,
      open: false,
      sideList: []
    };

    handleChange = (event, checked) => {
      this.setState({ auth: checked });
    };
  
    handleMenu = event => {
      this.setState({ anchorEl: event.currentTarget });
    };
  
    handleClose = () => {
      this.setState({ anchorEl: null });
    };

    openMenu = () => {
        this.setState({
          open: true
        });
    }
    
    closeMenu = () => {
        this.setState({
          open: false
        });
    }

    render() {
      const { classes } = this.props;
  
      return (
        <div className={classes.root}>
          <AppBar position="fixed">
            <Toolbar>
              <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.openMenu}>
                <MenuIcon />
              </IconButton>
              <Typography variant="title" color="inherit" className={classes.flex}>
                DevOps
              </Typography>
            </Toolbar>
          </AppBar>
          <NavMenu
              open={this.state.open}
              sideList={<Sidenav onMenuEntryClick={this.closeMenu}/>}
          >
          </NavMenu>
          {this.props.children}
        </div>
      );
  }
  }
  
  Layout.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles)(Layout);