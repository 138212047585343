const requestMenuEntriesType = 'REQUEST_MENU_ENTRIES';
const receiveMenuEntriesType = 'RECEIVE_MENU_ENTRIES';
const initialState = { entries: [], isLoading: false };

export const actionCreators = {
  requestMenuEntries: () => async (dispatch, getState) => {
    if (getState().menuEntries.entries.length > 0) {
      // Don't issue a duplicate request (we already have or are loading the requested data)
      return;
    }

    dispatch({ type: requestMenuEntriesType });

    const url = 'api/Security/MenuEntries';
    const response = await fetch(url);
    const entries = await response.json();

    dispatch({ type: receiveMenuEntriesType, entries });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestMenuEntriesType) {
    return {
      ...state,
      isLoading: true
    };
  }

  if (action.type === receiveMenuEntriesType) {
    return {
      ...state,
      entries: action.entries,
      isLoading: false
    };
  }

  return state;
};
